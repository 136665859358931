import React, { useState } from 'react'
import { postData } from '../../services/Request'
import Encrypter from '../../services/Encrypter'
import { useNavigate } from 'react-router-dom';
import Message from '../../components/Message/Message'
import Modal from 'react-bootstrap/Modal';
import FinishOperation from '../../components/Modals/FinishOperation'

function ConfirmTransferCard({ transferData, show, handleClose, transferType, resetTranferData, isSPCard }) {

    const navigate = useNavigate();
    const [password, setPassword] = useState('');
    const [alert, setAlert] = useState({ tipo: '', msn: '', show: false });

    const [cveRastreo, setreferenceNumber] = useState('');
    const [fromAccountName, setFromAccountName] = useState('');
    const [toAccountName, setToAccountName] = useState('');
    const [confirmDisabled, setConfirmDisabled] = useState(false);
    const [show2, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose2 = () => { setShow(false); setConfirmDisabled(false); resetTranferData(); };

    var displayOriginText = 'cuenta';
    var displayOrigin = '';
    var realDestination = '';

    realDestination = transferData.last_four ? transferData.last_four : transferData.destination;

    //validamos o procesamos de acuerdo al tipo de transferencias
    switch (transferData.trxType) {
        case 51:
            displayOrigin = transferData.origin;
            break;
        case 52:
            displayOrigin = transferData.origin;
            realDestination = 'XXXX-XXXX-XXXX-' + realDestination;
            break;
        case 53:
            displayOriginText = 'tarjeta';
            displayOrigin = transferData.originCard;
            break;
        case 54:
            displayOriginText = 'tarjeta';
            displayOrigin = transferData.originCard;
            realDestination = 'XXXX-XXXX-XXXX-' + realDestination;
            break;
        default:
            break;
    }

    const handleTransfer = () => {
        setConfirmDisabled(true);
        setAlert({ tipo: '', msn: '', show: false });
        const userData = JSON.parse(localStorage.getItem('userData'));
        const encryptedPass = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, password);
        const data = {
            trxType: transferData.trxType,
            accountFrom: transferData.origin,
            cardTo: transferData.destination,
            lastFour: transferData.last_four,
            phoneTo: transferData.phoneTo,
            concept: transferData.last_four ? 'Abono garantia: ' + transferData.last_four : transferData.destination,
            transferAmount: transferData.amount,
            refNumber: transferData.reference,
            opsPass: encryptedPass,
            email: transferData.email
        };
        const payObjStr = JSON.stringify(data);

        // AQUI METO UN NUEVO PROCESO DE FIRMA DIGITAL
        const baseDs = '/api' + process.env.REACT_APP_ACCOUNT_CARD_TRANSFER + 'spcard2' + userData.token + payObjStr;
        const ds = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, baseDs);
        const headers = { headers: { 'authorization': 'APIAuth ' + process.env.REACT_APP_API_KEY + ':' + ds } };
        const payment = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, payObjStr);
        const body = { data: payment };

        postData(process.env.REACT_APP_ACCOUNT_CARD_TRANSFER + 'spcard2/' + "?token=" + userData.token, body, headers)
            .then(({ response, error }) => {
                setConfirmDisabled(false);
                if (response) {
                    const responseData = JSON.parse(response);
                    setreferenceNumber(responseData.referenceNumber);
                    setFromAccountName(responseData.fromAccountName);
                    setToAccountName(responseData.toAccountName);
                    closeModal();
                    handleShow();
                    clearModal();
                } else if (error) {
                    const responseErrors = error.response.data.errors.errors;
                    if (error.response.status === 401) {
                        localStorage.removeItem('userData');
                        navigate('/');
                    } else if (responseErrors !== undefined) {
                        setAlert({ tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true });
                    } else {
                        console.log(error);
                        setAlert({ tipo: 'danger', msn: 'Error en el sistema', show: true });
                    }
                    return;
                }
            });
    };
    const closeAlert = () => {
        setAlert({ tipo: '', msn: '', show: false });
    };
    const clearModal = () => {
        setPassword('');
        setAlert({ tipo: '', msn: '', show: false });
    };
    const closeModal = () => {
        setAlert({ tipo: '', msn: '', show: false });
        handleClose();
    }

    const dollarUS = Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    });

    console.log(transferData);

    return (
        <div>
            <Modal show={show} onHide={closeModal} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title><h5 className="modal-title">Confirmar recarga de tarjeta</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="contModal">

                        <Message alert={alert} closeAlert={closeAlert} />
                        <div className="card card-caratula">
                            <div className="row row-form-text">
                                <div className="col-lg-6 content-label">
                                    <label className="col-form-label">De su {displayOriginText} número:</label>
                                </div>
                                <div className="col-lg-6 content-input">
                                    <input type="text" className="form-control txtControl" value={displayOrigin} readOnly />
                                </div>
                            </div>
                            <div className="row row-form-text">
                                <div className="col-lg-6 content-label">
                                    <label className="col-form-label">A la tarjeta número:</label>
                                </div>
                                <div className="col-lg-6 content-input">
                                    <input type="text" className="form-control txtControl" value={realDestination} readOnly />
                                </div>
                            </div>
                            {
                                ((transferData.trxType === 52) || (transferData.trxType === 54)) &&
                                <div className="row row-form-text">
                                    <div className="col-lg-6 content-label">
                                        <label className="col-form-label">Teléfono registrado de la tarjeta:</label>
                                    </div>
                                    <div className="col-lg-6 content-input">
                                        <input type="text" className="form-control txtControl" value={transferData.phoneTo} readOnly />
                                    </div>
                                </div>
                            }
                            <div className="row row-form-text">
                                <div className="col-lg-6 content-label">
                                    <label className="col-form-label">Monto a trasferir:</label>
                                </div>
                                <div className="col-lg-6 content-input">
                                    <input type="text" className="form-control txtControl" value={dollarUS.format(transferData.amount)} readOnly />
                                </div>
                            </div>
                            <div className="row row-form-text">
                                <div className="col-lg-6 content-label">
                                    <label className="col-form-label">Número de referencia:</label>
                                </div>
                                <div className="col-lg-6 content-input">
                                    <input type="number" className="form-control txtControl" value={transferData.reference} readOnly />
                                </div>
                            </div>
                            <div className="row row-form-text0">
                                <div className="col-lg-6 content-label">
                                    <label className="col-form-label">Ingrese su contraseña de operaciones:</label>
                                </div>
                                <div className="col-lg-6 content-input">
                                    <input type="password" id="operationPass" className="form-control txtControl" onChange={(e) => setPassword(e.target.value)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div>
                        <button type="button" onClick={handleTransfer} className="btn btn-primary2 btnModal" disabled={confirmDisabled}>Confirmar</button>
                    </div>
                </Modal.Footer>
            </Modal>
            <FinishOperation transferData={transferData} transferType={transferType} referenceNumber={cveRastreo} fromAccountName={fromAccountName} toAccountName={toAccountName} show={show2} handleClose={handleClose2} />
        </div>
    )
}

export default ConfirmTransferCard
